import {SET_SDATE_E, SET_EDATE_E, SET_SELECTED_INST_E, SET_TYPE_E, SET_EXPO_ORDER_QUERY, SET_EXPO_RETURN_QUERY, INIT_EXPO} from 'constants/ActionTypes';

const today = new Date()

const INIT_STATE = {
    sdate: new Date(today.getFullYear(), today.getMonth()),
    edate: new Date(today.getFullYear(), today.getMonth() + 1),
    selectedInst: localStorage.getItem('instCode'),
    type: "Hepsi",
    expoOrderQuery: [],
    expoReturnQuery: [],
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SDATE_E: {
            return {
                ...state,
                sdate: action.payload
            }
        }
        case SET_EDATE_E: {
            return {
                ...state,
                edate: action.payload
            }
        }
        case SET_SELECTED_INST_E: {
            return {
                ...state,
                selectedInst: action.payload
            }
        }
        case SET_TYPE_E: {
            return {
                ...state,
                type: action.payload
            }
        }
        case SET_EXPO_ORDER_QUERY: {
            return {
                ...state,
                expoOrderQuery: action.payload
            }
        }
        case SET_EXPO_RETURN_QUERY: {
            return {
                ...state,
                expoReturnQuery: action.payload
            }
        }
        case INIT_EXPO: {
            return {
                ...state,
                sdate: new Date(today.getFullYear(), today.getMonth()),
                edate: new Date(today.getFullYear(), today.getMonth() + 1),
                selectedInst: localStorage.getItem('instCode'),
                type: "Hepsi",
                expoOrderQuery: [],
                expoReturnQuery: [],
            }
        }
        default:
            return state;
    }
}
