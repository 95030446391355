import {SET_SDATE_E, SET_EDATE_E, SET_SELECTED_INST_E, SET_TYPE_E, SET_EXPO_ORDER_QUERY, SET_EXPO_RETURN_QUERY, INIT_EXPO} from 'constants/ActionTypes';

export const setSDateE = (sdate) => {
    return {
        type: SET_SDATE_E,
        payload: sdate
    };
};

export const setEDateE = (edate) => {
    return {
        type: SET_EDATE_E,
        payload: edate
    };
};

export const setSelectedInstE = (selectedInst) => {
    return {
        type: SET_SELECTED_INST_E,
        payload: selectedInst
    };
};

export const setTypeE = (type) => {
    return {
        type: SET_TYPE_E,
        payload: type
    };
};

export const setExpoOrderQuery = (expoOrder) => {
    return {
        type: SET_EXPO_ORDER_QUERY,
        payload: expoOrder
    };
};

export const setExpoReturnQuery = (expoReturn) => {
    return {
        type: SET_EXPO_RETURN_QUERY,
        payload: expoReturn
    };
};

export const initExpo = () => {
    return {
        type: INIT_EXPO,
    };
};