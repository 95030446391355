import {INIT_LMT, INIT_AMT, INIT_AT, SET_YEAR, SET_MONTH, SET_SELECTED_INST, SET_LIBRARY_MATERIAL, UPDATE_LIBRARY_MATERIAL, SET_LIBRARY_MATERIAL_MASS, SET_ARCHIVE_MATERIAL, UPDATE_ARCHIVE_MATERIAL, SET_ARCHIVE_MATERIAL_MASS, INIT_SEARCH} from 'constants/ActionTypes';

export const initLMT = (lmt) => {
    return {
        type: INIT_LMT,
        payload: lmt
    };
};

export const initAMT = (amt) => {
    return {
        type: INIT_AMT,
        payload: amt
    }
};

export const initAT = (at) => {
    return {
        type: INIT_AT,
        payload: at
    };
};

export const setYear = (year) => {
    return {
        type: SET_YEAR,
        payload: year
    };
};

export const setMonth = (month) => {
    return {
        type: SET_MONTH,
        payload: month
    };
};

export const setSelectedInst = (selectedInst) => {
    return {
        type: SET_SELECTED_INST,
        payload: selectedInst
    };
};

export const setLibraryMaterial = (libraryMaterial) => {
    return {
        type: SET_LIBRARY_MATERIAL,
        payload: libraryMaterial
    };
};

export const updateLibraryMaterial = (updateObj) => {
    return {
        type: UPDATE_LIBRARY_MATERIAL,
        payload: updateObj
    }
}

export const setLibraryMaterialMass = (libraryMaterialMass) => {
    return {
        type: SET_LIBRARY_MATERIAL_MASS,
        payload: libraryMaterialMass
    };
};

export const setArchiveMaterial = (archiveMaterial) => {
    return {
        type: SET_ARCHIVE_MATERIAL,
        payload: archiveMaterial
    };
};

export const updateArchiveMaterial = (updateObj) => {
    return {
        type: UPDATE_ARCHIVE_MATERIAL,
        payload: updateObj
    }
}

export const setArchiveMaterialMass = (archiveMaterialMass) => {
    return {
        type: SET_ARCHIVE_MATERIAL_MASS,
        payload: archiveMaterialMass
    };
}

export const initSearch = () => {
    return {
        type: INIT_SEARCH
    };
}