import {
    INIT_URL,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNIN_USER
} from 'constants/ActionTypes';

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};

export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};