import React from 'react';
import {connect} from 'react-redux'
import {userSignOutSuccess} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { localExit } from '../../appConfig';
import config from '../../config';

class UserInfoPopup extends React.Component {
  render() {
    return (
      <div>
        <div className="user-profile">
          <img className="user-avatar border-0 size-40 rounded-circle"
               src="https://via.placeholder.com/150x150"
               alt="User"/>
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">Chris Harris</h4>
            <small>Administrator</small>
          </div>
        </div>
        {/* <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.profile"/>
        </span>
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.setting"/>
        </span> */}
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          //Exit
          localExit()
          this.props.userSignOutSuccess()
          window.location.href = config.webBbsHost
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="popup.logout"/>
        </span>
      </div>
    );
  }
}

const mapStateToProps = ({settings,auth}) => {
  const {locale} = settings;
  //const {myuser} = auth;//kapanacak
  return {locale}
};
export default connect(mapStateToProps, {userSignOutSuccess})(UserInfoPopup);