import {INIT_LMT, INIT_AMT, INIT_AT, SET_YEAR, SET_MONTH, SET_SELECTED_INST, SET_LIBRARY_MATERIAL, UPDATE_LIBRARY_MATERIAL, SET_LIBRARY_MATERIAL_MASS, SET_ARCHIVE_MATERIAL, UPDATE_ARCHIVE_MATERIAL, SET_ARCHIVE_MATERIAL_MASS, INIT_SEARCH} from 'constants/ActionTypes';
import { head_inst, institutions } from '../appConfig';

const INIT_STATE = {
    libraryMaterialType: [],
    archiveMaterialType: [],
    acquisitionType: [],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    selectedInst: localStorage.getItem('instCode') === head_inst ? institutions[1].code : localStorage.getItem('instCode'),
    libraryMaterial: null,
    libraryMaterialMass: [],
    archiveMaterial: null,
    archiveMaterialMass: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_LMT: {
            return {
                ...state,
                libraryMaterialType: action.payload
            }
        }
        case INIT_AMT: {
            return {
                ...state,
                archiveMaterialType: action.payload
            }
        }
        case INIT_AT: {
            return {
                ...state,
                acquisitionType: action.payload
            }
        }
        case SET_YEAR: {
            return {
                ...state,
                year: action.payload
            }
        }
        case SET_MONTH: {
            return {
                ...state,
                month: action.payload
            }
        }
        case SET_SELECTED_INST: {
            return {
                ...state,
                selectedInst: action.payload
            }
        }
        case SET_LIBRARY_MATERIAL: {
            return {
                ...state,
                libraryMaterial: action.payload
            }
        }
        case UPDATE_LIBRARY_MATERIAL: {

            const {lmt_key, at_key, value} = action.payload
            const nlibraryMaterial = {...state.libraryMaterial}
            nlibraryMaterial[lmt_key][at_key] = value

            return {
                ...state,
                libraryMaterial: nlibraryMaterial
            }
        }
        case SET_LIBRARY_MATERIAL_MASS: {
            return {
                ...state,
                libraryMaterialMass: action.payload
            }
        }
        case SET_ARCHIVE_MATERIAL: {
            return {
                ...state,
                archiveMaterial: action.payload
            }
        }
        case UPDATE_ARCHIVE_MATERIAL: {

            const {amt_key, at_key, value} = action.payload
            const narchiveMaterial = {...state.archiveMaterial}
            narchiveMaterial[amt_key][at_key] = value

            return {
                ...state,
                archiveMaterial: narchiveMaterial
            }
        }
        case SET_ARCHIVE_MATERIAL_MASS: {
            return {
                ...state,
                archiveMaterialMass: action.payload
            }
        }
        case INIT_SEARCH: {
            return {
                ...state,
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                selectedInst: localStorage.getItem('instCode') === head_inst ? institutions[1].code : localStorage.getItem('instCode')
            }
        }
        default:
            return state;
    }
}
