import {INIT_LMT_R, INIT_AMT_R, INIT_AT_R, SET_SDATE_R, SET_EDATE_R, SET_SELECTED_INST_R, SET_LIBRARY_MATERIAL_QUERY, SET_ARCHIVE_MATERIAL_QUERY, SET_QUERY_YEAR_T, INIT_SEARCH_R, INIT_LRT_R, SET_LIBRARY_READER_QUERY, SET_QUERY_MONTH_T} from 'constants/ActionTypes';

export const initLMTR = (lmt) => {
    return {
        type: INIT_LMT_R,
        payload: lmt
    };
};

export const initAMTR = (amt) => {
    return {
        type: INIT_AMT_R,
        payload: amt
    }
};

export const initATR = (at) => {
    return {
        type: INIT_AT_R,
        payload: at
    };
};

export const initLRTR = (lrt) => {
    return {
        type: INIT_LRT_R,
        payload: lrt
    };
};

export const setSDateR = (sdate) => {
    return {
        type: SET_SDATE_R,
        payload: sdate
    };
};

export const setEDateR = (edate) => {
    return {
        type: SET_EDATE_R,
        payload: edate
    };
};

export const setSelectedInstR = (selectedInst) => {
    return {
        type: SET_SELECTED_INST_R,
        payload: selectedInst
    };
};

export const setLibraryMaterialQuery = (libraryMaterial) => {
    return {
        type: SET_LIBRARY_MATERIAL_QUERY,
        payload: libraryMaterial
    };
};

export const setArchiveMaterialQuery = (archiveMaterial) => {
    return {
        type: SET_ARCHIVE_MATERIAL_QUERY,
        payload: archiveMaterial
    };
};

export const setLibraryReaderQuery = (libraryReader) => {
    return {
        type: SET_LIBRARY_READER_QUERY,
        payload: libraryReader
    };
};

export const setQueryYearT = (queryYear) => {
    return {
        type: SET_QUERY_YEAR_T,
        payload: queryYear
    };
};

export const initSearchR = () => {
    return {
        type: INIT_SEARCH_R,
    };
};

export const setQueryMonthT = (queryMonth) => {
    return {
        type: SET_QUERY_MONTH_T,
        payload: queryMonth
    };
};