import {INIT_LMT_R, INIT_AMT_R, INIT_AT_R, SET_SDATE_R, SET_EDATE_R, SET_SELECTED_INST_R, SET_LIBRARY_MATERIAL_QUERY, SET_ARCHIVE_MATERIAL_QUERY, SET_QUERY_YEAR_T, INIT_SEARCH_R, INIT_LRT_R, SET_LIBRARY_READER_QUERY, SET_QUERY_MONTH_T} from 'constants/ActionTypes';

const today = new Date()

const INIT_STATE = {
    libraryMaterialType: [],
    archiveMaterialType: [],
    acquisitionType: [],
    sdate: new Date(today.getFullYear(), today.getMonth()),
    edate: new Date(today.getFullYear(), today.getMonth() + 1),
    selectedInst: localStorage.getItem('instCode'),
    libraryMaterialQuery: [],
    archiveMaterialQuery: [],
    queryYear: new Date().getFullYear(),
    libraryReaderType: [],
    libraryReaderQuery: [],
    queryMonth: 0
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_LMT_R: {
            return {
                ...state,
                libraryMaterialType: action.payload
            }
        }
        case INIT_AMT_R: {
            return {
                ...state,
                archiveMaterialType: action.payload
            }
        }
        case INIT_AT_R: {
            return {
                ...state,
                acquisitionType: action.payload
            }
        }
        case INIT_LRT_R: {
            return {
                ...state,
                libraryReaderType: action.payload
            }
        }
        case SET_SDATE_R: {
            return {
                ...state,
                sdate: action.payload
            }
        }
        case SET_EDATE_R: {
            return {
                ...state,
                edate: action.payload
            }
        }
        case SET_SELECTED_INST_R: {
            return {
                ...state,
                selectedInst: action.payload
            }
        }
        case SET_LIBRARY_MATERIAL_QUERY: {
            return {
                ...state,
                libraryMaterialQuery: action.payload
            }
        }
        case SET_ARCHIVE_MATERIAL_QUERY: {
            return {
                ...state,
                archiveMaterialQuery: action.payload
            }
        }
        case SET_LIBRARY_READER_QUERY: {
            return {
                ...state,
                libraryReaderQuery: action.payload
            }
        }
        case SET_QUERY_YEAR_T: {
            return {
                ...state,
                queryYear: action.payload
            }
        }
        case INIT_SEARCH_R: {
            return {
                ...state,
                sdate: new Date(today.getFullYear(), today.getMonth()),
                edate: new Date(today.getFullYear(), today.getMonth() + 1),
                selectedInst: localStorage.getItem('instCode'),
                queryYear: new Date().getFullYear(),
                queryMonth: 0
            }
        }
        case SET_QUERY_MONTH_T: {
            return {
                ...state,
                queryMonth: action.payload
            }
        }
        default:
            return state;
    }
}
