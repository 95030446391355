import { userSignOutSuccess } from "./actions/Auth";
import config from "./config";

export const head_inst = 'YK'
export const head_inst_managment = {name: 'Yüksek Kurum', code: 'AYK', id: 5}

export const institutions = [
  {name: 'Tüm Kurumlar', code: head_inst, id: 5},
  {name: 'Atatürk Araştırma Merkezi', code: 'ATAM', id: 1},
  {name: 'Atatürk Kültür Merkezi', code: 'AKM', id: 2},
  {name: 'Türk Dil Kurumu', code: 'TDK', id: 3},
  {name: 'Türk Tarih Kurumu', code: 'TTK', id: 4}
]

export const board_roles = [1, 62]
export const admin_roles = [1, 60, 61, 62]
export const library_roles = [1, 60, 61, 62, 63]

export const dig_auth_inst = [head_inst, "Türk Tarih Kurumu"]

export const material_year = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025]

export const material_month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz','Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

export const at_reduce_key = ['reduce']

export const getInstName = (inst) => {
  let institution = '';
  switch (inst) {
    case 'AKM': {
      institution = 'Atatürk Kültür Merkezi';
      break;
    }
    case 'ATAM': {
      institution = 'Atatürk Araştırma Merkezi';
      break;
    }
    case 'TDK': {
      institution = 'Türk Dil Kurumu';
      break;
    }
    case 'TTK': {
      institution = 'Türk Tarih Kurumu';
      break;
    }
    case 'YK': {
      institution = 'YK';
      break;
    }
    default : institution = '';
  }
  return institution;
}

export const getInstID = (inst) => {
  let institution = '';
  switch (inst) {
    case 'Atatürk Kültür Merkezi': {
      institution = '2';
      break;
    }
    case 'Atatürk Araştırma Merkezi': {
      institution = '1';
      break;
    }
    case 'Türk Dil Kurumu': {
      institution = '3';
      break;
    }
    case 'Türk Tarih Kurumu': {
      institution = '4';
      break;
    }
    case head_inst: {
      institution = '';
      break;
    }
    default : institution = '';
  }
  return institution;
}

export const localLogin = (user, token) => {
  localStorage.setItem("myuser", user.name+" "+user.surname);
  localStorage.setItem("userId", user.id);
  localStorage.setItem("roles", JSON.stringify(user.userRoles.map(r=>r.id)));
  localStorage.setItem("avatarurl", `${config.bbsHost}`+user.profileImageUrl);
  localStorage.setItem("inst", getInstName(user.inst));
  localStorage.setItem("instCode", user.inst);
  localStorage.setItem("token", token);
}

export const localExit = () => {
  localStorage.setItem("userId", null);
  localStorage.setItem("roles",null);
  localStorage.setItem("myuser", null);
  localStorage.setItem("avatarurl",null);
  localStorage.setItem("inst",null);
  localStorage.setItem("instCode",null);
  localStorage.setItem("token",null);
}

export const getReduxLoginUser = (user, token) => {
  return {
    myuser: user.name+" "+user.surname,
    userId: user.id,
    roles: user.userRoles.map(r=>r.id),
    avatarurl: `${config.bbsHost}`+user.profileImageUrl,
    inst: getInstName(user.inst),
    instCode: user.inst,
    token: token,
  }
}

export const notAuthAction = (e) => {
  if(e.toString().includes("403")){
    //Local Exit
    localExit()
    //Redux Exit
    this.props.dispatch(userSignOutSuccess())
  }
}

export const get_date_string = (ndate) => new Date(ndate).toLocaleDateString('en-GB').replace(/[\/\\:\?\*$$$$]/g, '')