import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
//import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import ColorOption from 'containers/Customizer/ColorOption';
class App extends React.Component {

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}
        <ColorOption />

        <Sidebar/>
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>}
            <Header/>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/bursmain`} component={asyncComponent(() => import('./routes/burs/bursmain.js'))}/>
                <Route path={`${match.url}/bursiyer1`} component={asyncComponent(() => import('./routes/burs/bursiyer1.js'))}/>
                <Route path={`${match.url}/burs1`} component={asyncComponent(() => import('./routes/burs/burs1.js'))}/>
                <Route path={`${match.url}/burs2`} component={asyncComponent(() => import('./routes/burs/burs2.js'))}/>
                <Route path={`${match.url}/etkinlikmain`} component={asyncComponent(() => import('./routes/etkinlik/etkinlikmain.js'))}/>
                <Route path={`${match.url}/etkinlik1`} component={asyncComponent(() => import('./routes/etkinlik/etkinlik1.js'))}/>
                <Route path={`${match.url}/etkinlik2`} component={asyncComponent(() => import('./routes/etkinlik/etkinlik2.js'))}/>
                <Route path={`${match.url}/etkinlik3`} component={asyncComponent(() => import('./routes/etkinlik/etkinlik3.js'))}/>
                <Route path={`${match.url}/proje1`} component={asyncComponent(() => import('./routes/proje/proje1.js'))}/>
                <Route path={`${match.url}/ucretsizyayin`} component={asyncComponent(() => import('./routes/kitapsatis/ucretsizyayin.js'))}/>
                <Route path={`${match.url}/fuar`} component={asyncComponent(() => import('./routes/kitapsatis/fuar.js'))}/>

                <Route path={`${match.url}/expo`} component={asyncComponent(() => import('./routes/kitapsatis/expo.js'))}/>

                <Route path={`${match.url}/kitapgelir`} component={asyncComponent(() => import('./routes/kitapsatis/kitapgelir.js'))}/>
                <Route path={`${match.url}/yayindevam`} component={asyncComponent(() => import('./routes/yayin/yayindevam.js'))}/>
                <Route path={`${match.url}/yayin`} component={asyncComponent(() => import('./routes/yayin/yayin.js'))}/>
                <Route path={`${match.url}/ykkUygulanma`} component={asyncComponent(() => import('./routes/board/ykkUygulanma.js'))}/>

                {/* Closed Routes */}
                {/* <Route path={`${match.url}/libmaterialreport`} component={asyncComponent(() => import('./routes/libreport/libmaterialreport.js'))}/> */}
                {/* <Route path={`${match.url}/libreaderreport`} component={asyncComponent(() => import('./routes/libreport/libreaderreport.js'))}/> */}
                {/* <Route path={`${match.url}/libstorereport`} component={asyncComponent(() => import('./routes/libreport/libstorereport.js'))}/> */}
                {/* <Route path={`${match.url}/arcstorereport`} component={asyncComponent(() => import('./routes/libreport/arcstorereport.js'))}/> */}

                {/* <Route path={`${match.url}/librarymaterial`} component={asyncComponent(() => import('./routes/library/librarymaterial.js'))}/> */}
                {/* <Route path={`${match.url}/libraryreaders`} component={asyncComponent(() => import('./routes/library/libraryreaders.js'))}/> */}
                {/* <Route path={`${match.url}/librarystore`} component={asyncComponent(() => import('./routes/library/librarystore.js'))}/> */}
                {/* <Route path={`${match.url}/archivestore`} component={asyncComponent(() => import('./routes/library/archivestore.js'))}/> */}
                

                <Route path={`${match.url}/libraryMaterialEntry`} component={asyncComponent(() => import('./routes/library/libraryMaterialEntry.js'))}/>
                <Route path={`${match.url}/archiveMaterialEntry`} component={asyncComponent(() => import('./routes/library/archiveMaterialEntry.js'))}/>
                <Route path={`${match.url}/libraryReaderEntry`} component={asyncComponent(() => import('./routes/library/libraryReaderEntry.js'))}/>

                <Route path={`${match.url}/libraryReaderReport`} component={asyncComponent(() => import('./routes/libreport/libraryReaderReport.js'))}/>
                <Route path={`${match.url}/libraryMaterialReport`} component={asyncComponent(() => import('./routes/libreport/libraryMaterialReport.js'))}/>
                <Route path={`${match.url}/libraryMaterialTable`} component={asyncComponent(() => import('./routes/libreport/libraryMaterialTable.js'))}/>
                <Route path={`${match.url}/archiveMaterialReport`} component={asyncComponent(() => import('./routes/libreport/archiveMaterialReport.js'))}/>
                <Route path={`${match.url}/archiveMaterialTable`} component={asyncComponent(() => import('./routes/libreport/archiveMaterialTable.js'))}/>
                <Route component={asyncComponent(() => import('components/Error404'))}/>
              </Switch>
            </div>
            <Footer/>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition}
};
export default withRouter(connect(mapStateToProps)(App));